import "./App.scss";

import { openDB } from "idb";
import md5 from "md5";

import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CozyPenguins from "./pages/collection/CozyPenguins";
import Woofys from "./pages/collection/Woofys";
import Home from "./pages/home";
import { LandslidePage } from "./pages/game/Landslide";
import { WaddleWarsPage } from "./pages/game/WaddleWars";
import { CozyversePage } from "./pages/game/CozyVerseDemo";
import { CozyverseAlphaPage } from "./pages/game/Cozyverse";
import CozyReef from "./pages/region/CozyReef";
import PolarPeaks from "./pages/region/PolarPeaks";
import {
  CollectionsCozyPenguins,
  CollectionsWoofys,
  GamesLandslide,
  GamesWaddleWars,
  GamesCozyverse,
  GamesCozyverseAlpha,
  RegionCozyReef,
  RegionCozyReefPortal,
  RegionPolarPeaksPortal,
  RegionPolarPeaks,
  RegionPolarPeaksRescue,
  RegionPolarPeaksCampsite,
  Story as StoryUrl,
} from "./constants/links";
import { useEffect } from "react";
import { AddNavToPage } from "./components/Nav";
import { Toaster } from "react-hot-toast";
import CozyReefPortal from "./pages/region/CozyReef/Portal";
import PolarPeaksPortal from "pages/region/PolarPeaks/Portal";
import Rescue from "pages/region/PolarPeaks/Rescue";
import Campsite from "pages/region/PolarPeaks/Campsite";
import { rescueEnabled } from "./pages/region/PolarPeaks";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, avalanche, avalancheFuji, sepolia } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { modalTheme, penguinAvatar } from "components/Web3/ConnectButton";
import ContractsProvider from "./providers/ContractsProvider";
import deployments from "./utils/web3/deployments";
import Story from "./pages/story";
import WoofyTraitsModal from "./components/WoofyTraitsModal";

const defaultChains =
  deployments.environmentName === "production"
    ? [mainnet, avalanche]
    : [sepolia, avalancheFuji];

const { chains, publicClient } = configureChains(
  [...defaultChains],
  [publicProvider()],
);

const { connectors } = getDefaultWallets({
  appName: "Cozyverse",
  projectId: "1781ae0f89ad163eedbfb2ca5aac691d",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

function App() {
  const fullHost = (protocol, host) => {
    return `${protocol}//${host}`;
  };

  const migrateKey = async (db, storeName: string, suffix: string = "") => {
    const legacyHost = fullHost(window.location.protocol, window.location.host);
    const newHost = fullHost(
      window.location.protocol,
      window.location.host + "/game",
    );
    const legacyKeyspace = md5(legacyHost);
    const newKeyspace = md5(newHost);

    const legacyKey = `/idbfs/${legacyKeyspace}${suffix}`;
    const newKey = `/idbfs/${newKeyspace}${suffix}`;

    const legacyValue = await db.get(storeName, legacyKey);
    const newValue = await db.get(storeName, newKey);

    if (!legacyValue) {
      console.log(`no legacy key ${legacyKey} found. exiting`);
      return;
    }
    if (!newValue) {
      await db.put(storeName, legacyValue, newKey);

      console.log(
        `copying legacy key \`${legacyKey}\` to \`${newKey}\` with value`,
      );
    }
  };

  useEffect(() => {
    if (!("indexedDB" in window)) {
      console.log("This browser doesn't support IndexedDB.");
    } else {
      const migrateIdbfsEntries = async () => {
        const storeName = "FILE_DATA";
        await openDB("/idbfs").then(async (db) => {
          if (!db.objectStoreNames.contains(storeName)) {
            return;
          }
          await migrateKey(db, storeName);
          await migrateKey(db, storeName, "/PlayerPrefs");
        });
      };

      migrateIdbfsEntries();
    }
  });

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        theme={modalTheme}
        modalSize="compact"
        chains={chains}
        avatar={penguinAvatar}
      >
        <Router>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              overflow: "clip",
            }}
          >
            <header></header>
            <Routes>
              <Route
                path="/"
                element={
                  <AddNavToPage>
                    <Home />
                  </AddNavToPage>
                }
              />
              <Route
                path={CollectionsCozyPenguins}
                element={
                  <AddNavToPage>
                    <CozyPenguins />
                  </AddNavToPage>
                }
              />
              <Route
                path={CollectionsWoofys}
                element={
                  <AddNavToPage>
                    <Woofys />
                  </AddNavToPage>
                }
              />
              <Route
                path={GamesLandslide}
                element={
                  <AddNavToPage>
                    <LandslidePage />
                  </AddNavToPage>
                }
              />
              <Route
                path={GamesWaddleWars}
                element={
                  <AddNavToPage>
                    <WaddleWarsPage />
                  </AddNavToPage>
                }
              />
              {deployments.environmentName === "staging" && (
                <Route
                  path={GamesCozyverse}
                  element={
                    <AddNavToPage>
                      <CozyversePage />
                    </AddNavToPage>
                  }
                />
              )}
              <Route
                path={GamesCozyverseAlpha}
                element={
                  <AddNavToPage>
                    <CozyverseAlphaPage />
                  </AddNavToPage>
                }
              />
              <Route
                path={RegionCozyReef}
                element={
                  <AddNavToPage>
                    <CozyReef />
                  </AddNavToPage>
                }
              />
              <Route
                path={RegionPolarPeaks}
                element={
                  <AddNavToPage>
                    <PolarPeaks />
                  </AddNavToPage>
                }
              />
              <Route
                path={RegionCozyReefPortal}
                element={
                  <ContractsProvider>
                    <AddNavToPage>
                      <CozyReefPortal />
                    </AddNavToPage>
                  </ContractsProvider>
                }
              />
              <Route
                path={RegionPolarPeaksPortal}
                element={
                  <ContractsProvider>
                    <AddNavToPage>
                      <PolarPeaksPortal />
                    </AddNavToPage>
                  </ContractsProvider>
                }
              />
              <Route
                path={RegionPolarPeaksCampsite}
                element={
                  <ContractsProvider>
                    <AddNavToPage>
                      <Campsite />
                    </AddNavToPage>
                  </ContractsProvider>
                }
              />
              {rescueEnabled && (
                <Route
                  path={RegionPolarPeaksRescue}
                  element={
                    <ContractsProvider>
                      <AddNavToPage>
                        <Rescue />
                      </AddNavToPage>
                    </ContractsProvider>
                  }
                />
              )}
              <Route
                path={StoryUrl}
                element={
                  <AddNavToPage>
                    <Story />
                  </AddNavToPage>
                }
              />
            </Routes>
            <Footer />
            <Toaster
              position="bottom-center"
              containerStyle={{
                zIndex: 999999,
              }}
            />
          </div>
        </Router>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
